import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  jugadores: {
    isLoading: false,
    error: false,
    data: []
  },
  jugadoresByCategoria: {
    isLoading: false,
    error: false,
    data: []
  },
  jugador: {
    isLoading: false,
    error: false,
    data: {}
  }
};

const sliceName = 'jugadoresSlice';

const jugadoresSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    fetchJugadoresStart(state) {
      state.jugadores.isLoading = true;
      state.jugadores.error = false;
    },
    fetchJugadoresSuccess(state, action) {
      state.jugadores.isLoading = false;
      state.jugadores.error = false;
      state.jugadores.data = action.payload;
    },
    fetchJugadoresFail(state) {
      state.jugadores.isLoading = false;
      state.jugadores.error = true;
    },
    fetchJugadoresByCategoriaStart(state) {
      state.jugadoresByCategoria.isLoading = true;
      state.jugadoresByCategoria.error = false;
    },
    fetchJugadoresByCategoriaSuccess(state, action) {
      state.jugadoresByCategoria.isLoading = false;
      state.jugadoresByCategoria.error = false;
      state.jugadoresByCategoria.data = action.payload;
    },
    fetchJugadoresByCategoriaFail(state) {
      state.jugadoresByCategoria.isLoading = false;
      state.jugadoresByCategoria.error = true;
    },
    addJugadorStart(state) {
      state.jugador.isLoading = true;
      state.jugador.error = false;
    },
    addJugadorSuccess(state, action) {
      state.jugador.isLoading = false;
      state.jugador.error = false;
      state.jugador.data = action.payload;
    },
    addJugadorFail(state) {
      state.jugador.isLoading = false;
      state.jugador.error = true;
    },
    updateJugadorStart(state) {
      state.jugador.isLoading = true;
      state.jugador.error = false;
    },
    updateJugadorSuccess(state, action) {
      state.jugador.isLoading = false;
      state.jugador.error = false;
      // Update the player in the players list
      state.jugadores.data = state.jugadores.data.map(jugador =>
        jugador.id === action.payload.id ? action.payload : jugador
      );
    },
    updateJugadorFail(state) {
      state.jugador.isLoading = false;
      state.jugador.error = true;
    },
    setJugador(state, action) {
      state.jugador.data = action.payload;
    }
  }
});

export const fetchJugadoresAction = createAction(`${sliceName}/fetchJugadoresAction`);
export const fetchJugadoresByCategoriaAction = createAction(`${sliceName}/fetchJugadoresByCategoriaAction`);
export const addJugadorAction = createAction(`${sliceName}/addJugadorAction`);
export const updateJugadorAction = createAction(`${sliceName}/updateJugadorAction`);

const { actions, reducer } = jugadoresSlice;

export const {
  fetchJugadoresStart,
  fetchJugadoresSuccess,
  fetchJugadoresFail,
  fetchJugadoresByCategoriaStart,
  fetchJugadoresByCategoriaSuccess,
  fetchJugadoresByCategoriaFail,
  addJugadorStart,
  addJugadorSuccess,
  addJugadorFail,
  updateJugadorStart,
  updateJugadorSuccess,
  updateJugadorFail,
  setJugador
} = actions;

export const getJugadoresDataSelector = state => state.jugadores.jugadores.data;
export const getJugadoresByCategoriaDataSelector = state => state.jugadores.jugadoresByCategoria.data;
export const getJugadorDataSelector = state => state.jugadores.jugador.data;

export default reducer;
