import axios from "axios";
import tokenService from "../util/tokenService";

const baseUrl = process.env.REACT_APP_BASE_URL
axios.defaults.baseURL = baseUrl

const api = axios.create({
    baseUrl: baseUrl
});

api.interceptors.request.use(config => {
    
    const token = tokenService.getToken();
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => Promise.reject(error));

api.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const newTokens = await tokenService.refreshToken();
            if (newTokens) {
                api.defaults.headers.common['Authorization'] = `Bearer ${newTokens.accessToken}`;
                return api(originalRequest);
            }
        } catch (refreshError) {
            tokenService.removeToken();
        }
    }
    return Promise.reject(error);
});

export const httpGet = (uri) => api.get(uri);
export const httpPut = (url, params) => api.put(url, params);
export const httpPost = (url, params) => api.post(url, params);
export const httpPatch = (url, params) => api.patch(url, params);
