import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataSelector, refreshSessionAction } from '../slice/auth';
import tokenService from '../util/tokenService';
import offlineRoutes from './offline';
import onlineRoutes from './online';

function Routes() {
    const dispatch = useDispatch();
    const userData = useSelector(getUserDataSelector);

    useEffect(() => {
        if (tokenService.getToken()) {
            dispatch(refreshSessionAction());
        }
    }, [dispatch]);

    const routes = userData.id ? [...offlineRoutes, ...onlineRoutes] : offlineRoutes;
    const element = useRoutes(routes);

    return element;
}

export default Routes;
