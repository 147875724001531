import React from 'react';
import { Box, Typography, TextField, Button, Modal } from '@mui/material';

const CustomModal = ({ open, handleClose, handleSave, title, inputLabel, inputValue, setInputValue }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
        <TextField
          label={inputLabel}
          variant="outlined"
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ margin: '20px 0' }}
        />
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="secondary" onClick={handleClose} style={{ marginRight: '10px' }}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave} disabled={!inputValue}>
            Guardar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default CustomModal;
