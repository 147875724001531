import { httpGet, httpPost, httpPut } from './httpService';

// Obtiene todas las categorías
export const getCategorias = async () => {
    const url = '/categorias';
    const res = await httpGet(url);
    return res;
};

// Añade una nueva categoría
export const addCategoriaApi = async (data) => {
    const url = '/categorias';
    const res = await httpPost(url, data);
    return res;
};

// Actualiza una categoría existente
export const updateCategoriaApi = async (data) => {
    const url = `/categorias/${data.id}`;
    const res = await httpPut(url, {nombre: data.nombre});
    return res;
};

// Obtiene categorías por ID de club
export const getCategoriasByIdClub = async (id) => {
    const url = `/categorias/club/${id}`;
    const res = await httpGet(url);
    return res;
};
