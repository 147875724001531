import { all } from 'redux-saga/effects';
import authSagas from './auth';
import clubesSagas from './clubes';
import categoriasSagas from './categorias';
import jugadoresSagas from './jugadores';


export function* rootSaga() {
    yield all([
        authSagas(),
        clubesSagas(),
        categoriasSagas(),
        jugadoresSagas()
    ]);
}