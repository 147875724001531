// Jugador.js
import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Zoom } from '@mui/material';

const imgBaseUrl = process.env.REACT_APP_BASE_IMGS_URL;

const StyledCard = styled(Card)({
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: '8px',
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
});

const Jugador = ({ jugador }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <StyledCard>
        <CardMedia
          component="img"
          sx={{ width: 150, height: 150 }}
          image={`${imgBaseUrl}${jugador.fotoCara}`}
          alt={`${jugador.nombre} ${jugador.apellido}`}
        />
        <CardContent>
          <Typography variant="h6">{`${jugador.nombre} ${jugador.apellido}`}</Typography>
          <Typography color="text.secondary">{`DNI: ${jugador.dni}`}</Typography>
          <IconButton onClick={handleClickOpen}>
            <PhotoCamera />
          </IconButton>
        </CardContent>
      </StyledCard>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        TransitionComponent={Zoom}
      >
        <DialogTitle>Foto DNI</DialogTitle>
        <DialogContent>
          <img
            src={`${imgBaseUrl}${jugador.fotoDni}`}
            alt="Foto DNI"
            style={{ width: '100%', height: 'auto' }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Jugador;
