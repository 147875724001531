import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addClubAction, fetchClubesAction, getClubDataSelector, getClubesDataSelector, IsClubesLoading, setClub } from '../../slice/clubes';
import { Container, Grid, Card, CardContent, Typography, Button, TextField, Box } from '@mui/material';
import { styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { push } from 'redux-first-history';
import CustomModal from '../../components/Modal';

const adminUrl = process.env.REACT_APP_BASE_ADMIN_URL;

const Clubes = () => {
  const dispatch = useDispatch();
  const clubes = useSelector(getClubesDataSelector);
  const club = useSelector(getClubDataSelector);
  const LoadingClubes = useSelector(IsClubesLoading);

  useEffect(() => {
    dispatch(fetchClubesAction());
  }, [dispatch, club]);

  const [open, setOpen] = useState(false);
  const [newClubName, setNewClubName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const handleCardClick = (club) => {
    dispatch(setClub(club));
    dispatch(push(`${adminUrl}/clubes/${club.id}`));
  };

  const handleAddClub = () => {
    dispatch(addClubAction({ nombre: newClubName }));
    handleClose();
    setNewClubName('');
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredClubes = clubes.filter((club) =>
    club.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (LoadingClubes) return <p>Cargando...</p>;
  return (
    <Container>
      <TextField
        label="Buscar club"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        fullWidth
        sx={{ marginBottom: '20px' }}
      />
      <Box display="flex" justifyContent="flex-end" marginBottom="20px">
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          startIcon={<AddIcon />}
          sx={{ marginBottom: '20px' }}
        >
          Agregar club
        </Button>
      </Box>
      <Grid container spacing={2}>
        {filteredClubes.map((club) => (
          <Grid item xs={12} sm={6} md={4} key={club.id}>
            <StyledCard onClick={() => handleCardClick(club)}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {club.nombre}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <CustomModal
        open={open}
        handleClose={handleClose}
        handleSave={handleAddClub}
        title="Agregar nuevo club"
        inputLabel="Nombre del club"
        inputValue={newClubName}
        setInputValue={setNewClubName}
      />
    </Container>
  );
};

const StyledCard = styled(Card)({
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: '8px',
  transition: 'transform 0.2s, box-shadow 0.2s',
  marginBottom: '20px',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
  },
});

export default Clubes;
