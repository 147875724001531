import axios from 'axios';

const TOKEN_KEY = 'authToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

const getToken = () => {
    const accessToken = JSON.parse(localStorage.getItem(TOKEN_KEY));
    return accessToken ? accessToken : null;
};

const getRefreshToken = () => {
    const refreshToken = JSON.parse(localStorage.getItem(REFRESH_TOKEN_KEY));
    return refreshToken ? refreshToken : null;
};

const setToken = (tokenData) => {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenData));
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenData}`;
};

const setRefreshToken = (tokenData) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(tokenData));
};

const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    delete axios.defaults.headers.common['Authorization'];
};

const refreshToken = async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) return null;

    try {
        const response = await axios.post('/auth/refresh', { refreshToken });
        const newTokens = response.data;
        setToken(newTokens.accessToken);
        setRefreshToken(newTokens.refreshToken);
        return newTokens;
    } catch (error) {
        removeToken();
        throw error;
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getToken,
    getRefreshToken,
    setToken,
    setRefreshToken,
    removeToken,
    refreshToken,
};
