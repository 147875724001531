import {lazy} from 'react';
import Loader from '../components/Loader';


const App = Loader(lazy(() => import('../App')));
const LoginScreen = Loader(lazy(() => import('../pages/ofline/Login')));
const NotFound = Loader(lazy(() => import('../pages/ofline/NotFund')));

const offlineRoutes = [
  {
    path: '/',
    element: <App />
  },
  {
    path: `/login`,
    element: <LoginScreen />
  },
  {
    path: '*',
    element: <NotFound />
  },
];

export default offlineRoutes;