import { httpGet, httpPost, httpPut } from './httpService';

export const getClubes = async () => {
    const url = '/clubes';
    const res = await httpGet(url);
    return res;
};

export const getClubByIdApi = async (id) => {
    const url = `/clubes/${id}`;
    const res = await httpGet(url);
    return res;
};

export const addClubApi = async (data) => {
    const url = '/clubes';
    const res = await httpPost(url, data);
    return res;
};

export const updateClubApi = async (data) => {
    const url = `/clubes/${data.id}`;
    const res = await httpPut(url, { nombre: data.nombre });
    return res;
};
