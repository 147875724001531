import { httpGet, httpPost, httpPut } from './httpService';

export const getJugadores = async () => {
    const url = '/jugadores';
    const res = await httpGet(url);
    return res;
};

export const addJugadorApi = async (data) => {
    const url = '/jugadores';
    const res = await httpPost(url, data);
    return res;
};

export const updateJugadorApi = async (data) => {
    const url = `/jugadores/${data.id}`;
    const res = await httpPut(url, data);
    return res;
};

export const getJugadoresByCategoria = async (categoriaId) => {
    const url = `/jugadores/categoria/${categoriaId}`;
    const res = await httpGet(url);
    return res;
};
