import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClubByIdAction, getClubDataSelector, IsClubesLoading, updateClubAction } from '../../slice/clubes';
import { Container, Card, CardContent, Typography, Button, List, ListItem, ListItemText, IconButton, Box } from '@mui/material';
import { styled } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import { fetchCategoriasByIdClubAction, getCategoriasByIdClubDataSelector, isLoadingCategoriasById, updateCategoriaAction, addCategoriaAction } from '../../slice/categorias';
import CustomModal from '../../components/Modal';
import { push } from 'redux-first-history';
const adminUrl = process.env.REACT_APP_BASE_ADMIN_URL;

const EditClub = () => {
  const dispatch = useDispatch();
  const club = useSelector(getClubDataSelector);
  const categorias = useSelector(getCategoriasByIdClubDataSelector);
  const LoadingClubes = useSelector(IsClubesLoading);
  const loadingCategoriasById = useSelector(isLoadingCategoriasById);
  const { idClub } = useParams();

  useEffect(() => {
    dispatch(fetchCategoriasByIdClubAction(idClub));
    !!!club.nombre && dispatch(fetchClubByIdAction(idClub));
  }, [dispatch, idClub, club]);

  const [open, setOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [newClubName, setNewClubName] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');
  const [categoryIdToEdit, setCategoryIdToEdit] = useState(null);

  const handleEditClick = () => {
    setNewClubName(club?.nombre);
    setOpen(true);
  };

  const handleEditCategoryClick = (id, nombre) => {
    setCategoryIdToEdit(id);
    setNewCategoryName(nombre);
    setOpenCategory(true);
  };

  const handleAddCategoryClick = () => {
    setNewCategoryName('');
    setOpenAddCategory(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCategory(false);
    setOpenAddCategory(false);
  };

  const handleSave = () => {
    dispatch(updateClubAction({ id: club.id, nombre: newClubName }));
    handleClose();
  };

  const handleSaveCategory = () => {
    dispatch(updateCategoriaAction({ id: categoryIdToEdit, nombre: newCategoryName, idClub }));
    handleClose();
  };

  const handleAddCategory = () => {
    dispatch(addCategoriaAction({ nombre: newCategoryName, club: idClub }));
    handleClose();
  };

  const goToPlantel = (id) => {
    console.log('Go to plantel with category id:', id);
    dispatch(push(`${adminUrl}/categoria/${id}`));
  };

  if (LoadingClubes && loadingCategoriasById) return <p>Cargando...</p>;

  return (
    <Container>
      <StyledCard>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" component="div">
              {club?.nombre || '...'}
            </Typography>
            <Button onClick={handleEditClick}>
              <EditIcon />
            </Button>
          </Box>
        </CardContent>
      </StyledCard>

      <Box display="flex" justifyContent="flex-end" marginBottom="20px">
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCategoryClick}
          startIcon={<AddIcon />}
        >
          Agregar categoría
        </Button>
      </Box>

      <Typography variant="h6" component="div" marginBottom="20px">
        Categorías
      </Typography>
      <List>
        {categorias.map((categoria) => (
          <ListItem key={categoria.id} divider>
            <ListItemText primary={categoria.nombre} />
            <IconButton edge="end" onClick={() => handleEditCategoryClick(categoria.id, categoria.nombre)}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" onClick={() => goToPlantel(categoria.id)}>
              <GroupIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>

      <CustomModal
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        title="Editar nombre del club"
        inputLabel="Nombre del club"
        inputValue={newClubName}
        setInputValue={setNewClubName}
      />

      <CustomModal
        open={openCategory}
        handleClose={handleClose}
        handleSave={handleSaveCategory}
        title="Editar nombre de la categoría"
        inputLabel="Nombre de la categoría"
        inputValue={newCategoryName}
        setInputValue={setNewCategoryName}
      />

      <CustomModal
        open={openAddCategory}
        handleClose={handleClose}
        handleSave={handleAddCategory}
        title="Agregar nueva categoría"
        inputLabel="Nombre de la categoría"
        inputValue={newCategoryName}
        setInputValue={setNewCategoryName}
      />
    </Container>
  );
};

const StyledCard = styled(Card)({
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: '8px',
  marginBottom: '20px',
});

export default EditClub;
