import {lazy} from 'react';
import Loader from '../components/Loader';
import Clubes from '../pages/online/Clubes';
import EditClub from '../pages/online/EditClub';
import CategoriaJugadores from '../pages/online/Categoria';

const DashScreen = Loader(lazy(() => import('../pages/online/Dashboard')));

const adminUrl = process.env.REACT_APP_BASE_ADMIN_URL

const onlineRoutes = [
  {
    path: `${adminUrl}`,
    element: <DashScreen
        title="Bienvenido al panel de administración" 
        description= "Aquí podes gestionar todos los datos de su aplicacion."
        subDescription= "Tambien puede seleccionar en el menu de la izquierda el modulo al que desea ingresar"
    />
  },
  {
    path: `${adminUrl}/clubes`,
    element: <DashScreen 
        children={<Clubes />} 
        title="Administracion de Clubes"
        description="Administra y organiza todos los clubes"
        subDescription="Desde aquí puedes agregar nuevos clubes y editar información existente."
    />
  },
  {
    path: `${adminUrl}/clubes/:idClub`,
    element: <DashScreen 
        children={<EditClub />} 
        title="Editar Club"
        description="Modifica la información del club seleccionado"
        subDescription="Aquí puedes agregar y editar categorías del club, así como actualizar otros detalles del club seleccionado."
    />
  },
  {
    path: `${adminUrl}/categoria/:idCategoria`,
    element: <DashScreen 
        children={<CategoriaJugadores />} 
        title="Detalles del Equipo"
        description="Revisa y actualiza la información del equipo seleccionado"
        subDescription="Aquí puedes ver y modificar los detalles del equipo, como jugadores y otros aspectos importantes."        
    />
  }
];

export default onlineRoutes;