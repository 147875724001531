import { httpGet, httpPost } from "./httpService";

export const signIn = async (data) => {
    const url = '/auth/login';
    const res = await httpPost(url, data);
    return res;
};

export const refreshSessionApi = async () => {
    const url = '/auth/userData';
    const res = await httpGet(url);
    return res;
};