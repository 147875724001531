import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../sagas';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import {reducers} from '../slice';

// Crea el middleware de saga
const sagaMiddleware = createSagaMiddleware();

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
  } = createReduxHistoryContext({ history: createBrowserHistory() });

const store = configureStore({
    reducer: {
        router: routerReducer,
        ...reducers
        
      },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware, routerMiddleware),
});

// Ejecuta el saga
sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);

export default store;
