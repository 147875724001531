import { call, put, takeLatest } from 'redux-saga/effects';
import {
  addJugadorAction,
  addJugadorFail,
  addJugadorStart,
  addJugadorSuccess,
  fetchJugadoresAction,
  fetchJugadoresFail,
  fetchJugadoresStart,
  fetchJugadoresSuccess,
  fetchJugadoresByCategoriaAction,
  fetchJugadoresByCategoriaFail,
  fetchJugadoresByCategoriaStart,
  fetchJugadoresByCategoriaSuccess,
  updateJugadorAction,
  updateJugadorFail,
  updateJugadorStart,
  updateJugadorSuccess
} from '../slice/jugadores';
import { addJugadorApi, getJugadores, getJugadoresByCategoria, updateJugadorApi } from '../api/jugadores';
import { setClub } from '../slice/clubes';
import { setCategoria } from '../slice/categorias';

export function* fetchJugadores() {
  try {
    yield put(fetchJugadoresStart());
    const { data } = yield call(getJugadores);
    yield put(fetchJugadoresSuccess(data));
  } catch (err) {
    yield put(fetchJugadoresFail());
  }
}

export function* fetchJugadoresByCategoria({ payload }) {
  try {
    yield put(fetchJugadoresByCategoriaStart());
    const { data } = yield call(getJugadoresByCategoria, payload);
    yield put(fetchJugadoresByCategoriaSuccess(data.jugadores));
    yield put(setClub(data.categoria.club));
    yield put(setCategoria(data.categoria));
  } catch (err) {
    yield put(fetchJugadoresByCategoriaFail());
  }
}

export function* addJugador({ payload }) {
  try {
    yield put(addJugadorStart());
    const { data } = yield call(addJugadorApi, payload);
    yield put(addJugadorSuccess(data));
    yield put(fetchJugadoresAction()); // Refresca la lista de jugadores después de agregar uno nuevo
  } catch (err) {
    yield put(addJugadorFail());
  }
}

export function* updateJugador({ payload }) {
  try {
    yield put(updateJugadorStart());
    const { data } = yield call(updateJugadorApi, payload);
    yield put(updateJugadorSuccess(data));
    yield put(fetchJugadoresAction()); // Refresca la lista de jugadores después de actualizar uno
  } catch (err) {
    yield put(updateJugadorFail());
  }
}

export default function* jugadoresSagas() {
  yield takeLatest(fetchJugadoresAction, fetchJugadores);
  yield takeLatest(fetchJugadoresByCategoriaAction, fetchJugadoresByCategoria);
  yield takeLatest(addJugadorAction, addJugador);
  yield takeLatest(updateJugadorAction, updateJugador);
}
