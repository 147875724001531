import { push } from "redux-first-history";
import { signIn, refreshSessionApi } from "../api/auth";
import { refreshSessionAction, refreshSessionFail, refreshSessionStart, refreshSessionSuccess, signInAction, signInFail, signInStart, signInSuccess } from "../slice/auth";
import tokenService from "../util/tokenService";
import {call, put, takeLatest} from 'redux-saga/effects';

const adminUrl = process.env.REACT_APP_BASE_ADMIN_URL

  export function* login({payload}) {
  
    try {
        yield put(signInStart());
        const {data} = yield call(signIn, payload);
        yield call(tokenService.setToken, data.accessToken);
        yield call(tokenService.setRefreshToken, data.refreshToken);
        yield put(signInSuccess(data.user));
        yield put(push(adminUrl));
    } catch (err) {
      yield put(signInFail());
    }
  }

  export function* refreshSession() {
    try {
        yield put(refreshSessionStart());
        const {data} = yield call(refreshSessionApi);
        yield put(refreshSessionSuccess(data));
    } catch (err) {
      yield put(refreshSessionFail());
      yield put(push('/'));
    }
  }

export default function* authSagas() {
    yield takeLatest(signInAction, login);
    yield takeLatest(refreshSessionAction, refreshSession);
}
  