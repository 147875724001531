import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  signIn: {
    isLoading: false,
    error: false,
    data: null
  }
};

const sliceName = 'authSlice';

const authSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    signInStart(state) {
      state.signIn.isLoading = true;
      state.signIn.error = false;
    },
    signInSuccess(state, action) {
      state.signIn.isLoading = false;
      state.signIn.error = false;
      state.signIn.data = action.payload;
    },
    signInFail(state) {
      state.signIn.isLoading = false;
      state.signIn.error = true;
    },
    refreshSessionStart(state) {
      state.signIn.isLoading = true;
      state.signIn.error = false;
    },
    refreshSessionSuccess(state, action) {
      state.signIn.isLoading = false;
      state.signIn.error = false;
      state.signIn.data = action.payload;
    },
    refreshSessionFail(state) {
      state.signIn.isLoading = false;
      state.signIn.error = true;
    }
  },
  
});

export const signInAction = createAction(`${sliceName}/signInAction`);
export const refreshSessionAction = createAction(`${sliceName}/refreshSessionAction`);

const { actions, reducer } = authSlice;

export const {
  signInStart,
  signInSuccess,
  signInFail,
  refreshSessionStart,
  refreshSessionSuccess,
  refreshSessionFail
} = actions;

// Selector para obtener los datos del usuario
export const getUserDataSelector = createSelector(
  state => state.auth.signIn.data,
  signIn => ({
    ...signIn
  })
);

export default reducer;
