// CategoriaJugadores.js
import React, { useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import Jugador from '../../components/Jugador';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJugadoresByCategoriaAction, getJugadoresByCategoriaDataSelector } from '../../slice/jugadores';
import { getClubDataSelector } from '../../slice/clubes';
import { getCategoriaDataSelector } from '../../slice/categorias';
const adminUrl = process.env.REACT_APP_BASE_ADMIN_URL;

const CategoriaJugadores = () => {
    const dispatch = useDispatch()
    const { idCategoria } = useParams();
    const jugadores = useSelector(getJugadoresByCategoriaDataSelector)
    const club = useSelector(getClubDataSelector)
    const categoria = useSelector(getCategoriaDataSelector)

    useEffect(() => {
        dispatch(fetchJugadoresByCategoriaAction(idCategoria))
      }, [dispatch, idCategoria]);

    return (
        <Container>
            <Typography variant="h5" gutterBottom>
                Club: <Link to={`${adminUrl}/clubes/${club.id}`} style={{ textDecoration: 'none', color: 'blue' }}>
                    {club.nombre}
                </Link>
            </Typography>
            <Typography variant="h6" gutterBottom>
                Categoria : {categoria.nombre}
            </Typography>
        {jugadores.map((jugador) => (
            <Jugador key={jugador.id} jugador={jugador} />
        ))}
        </Container>
    );
};

    export default CategoriaJugadores;
