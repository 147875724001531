import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  categorias: {
    isLoading: false,
    error: false,
    data: []
  },
  categoria: {
    isLoading: false,
    error: false,
    data: []
  },
  categoriasByIdClub: {
    isLoading: false,
    error: false,
    data: []
  }
};

const sliceName = 'categoriaSlice';

const categoriaSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    fetchCategoriasStart(state) {
      state.categorias.isLoading = true;
      state.categorias.error = false;
    },
    fetchCategoriasSuccess(state, action) {
      state.categorias.isLoading = false;
      state.categorias.error = false;
      state.categorias.data = action.payload;
    },
    fetchCategoriasFail(state) {
      state.categorias.isLoading = false;
      state.categorias.error = true;
    },
    addCategoriaStart(state) {
      state.categoria.isLoading = true;
      state.categoria.error = false;
    },
    addCategoriaSuccess(state, action) {
      state.categoria.isLoading = false;
      state.categoria.error = false;
      state.categoria.data = action.payload;
    },
    addCategoriaFail(state) {
      state.categoria.isLoading = false;
      state.categoria.error = true;
    },
    updateCategoriaStart(state) {
      state.categoria.isLoading = true;
      state.categoria.error = false;
    },
    updateCategoriaSuccess(state, action) {
      state.categoria.isLoading = false;
      state.categoria.error = false;
      state.categorias.data = state.categorias.data.map(cat =>
        cat.id === action.payload.id ? action.payload : cat
      );
    },
    updateCategoriaFail(state) {
      state.categoria.isLoading = false;
      state.categoria.error = true;
    },
    fetchCategoriasByIdClubStart(state) {
      state.categoriasByIdClub.isLoading = true;
      state.categoriasByIdClub.error = false;
    },
    fetchCategoriasByIdClubSuccess(state, action) {
      state.categoriasByIdClub.isLoading = false;
      state.categoriasByIdClub.error = false;
      state.categoriasByIdClub.data = action.payload;
    },
    fetchCategoriasByIdClubFail(state) {
      state.categoriasByIdClub.isLoading = false;
      state.categoriasByIdClub.error = true;
    },
    setCategoria(state, action) {
        state.categoria.data = action.payload;
      }
  },
});

export const fetchCategoriasAction = createAction(`${sliceName}/fetchCategoriasAction`);
export const addCategoriaAction = createAction(`${sliceName}/addCategoriaAction`);
export const updateCategoriaAction = createAction(`${sliceName}/updateCategoriaAction`);
export const fetchCategoriasByIdClubAction = createAction(`${sliceName}/fetchCategoriasByIdClubAction`);

const { actions, reducer } = categoriaSlice;

export const {
  fetchCategoriasStart,
  fetchCategoriasSuccess,
  fetchCategoriasFail,
  addCategoriaStart,
  addCategoriaSuccess,
  addCategoriaFail,
  updateCategoriaStart,
  updateCategoriaSuccess,
  updateCategoriaFail,
  fetchCategoriasByIdClubStart,
  fetchCategoriasByIdClubSuccess,
  fetchCategoriasByIdClubFail,
  setCategoria
} = actions;

export const getCategoriasDataSelector = state => state.categorias.categorias.data;
export const getCategoriaDataSelector = state => state.categorias.categoria.data;
export const getCategoriasByIdClubDataSelector = state => state.categorias.categoriasByIdClub.data;
export const isLoadingCategoriasById = state => state.categorias.categoriasByIdClub.isLoading;

export default reducer;
