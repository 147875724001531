import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchCategoriasAction,
  fetchCategoriasFail,
  fetchCategoriasStart,
  fetchCategoriasSuccess,
  addCategoriaAction,
  addCategoriaFail,
  addCategoriaStart,
  addCategoriaSuccess,
  updateCategoriaAction,
  updateCategoriaFail,
  updateCategoriaStart,
  updateCategoriaSuccess,
  fetchCategoriasByIdClubAction,
  fetchCategoriasByIdClubFail,
  fetchCategoriasByIdClubStart,
  fetchCategoriasByIdClubSuccess
} from '../slice/categorias';
import {
  addCategoriaApi,
  getCategorias,
  updateCategoriaApi,
  getCategoriasByIdClub
} from '../api/categorias.js'; // Asegúrate de que la ruta al archivo de API sea correcta

export function* fetchCategorias() {
  try {
    yield put(fetchCategoriasStart());
    const { data } = yield call(getCategorias);
    yield put(fetchCategoriasSuccess(data));
  } catch (err) {
    yield put(fetchCategoriasFail());
  }
}

export function* addCategoria({ payload }) {
  try {
    yield put(addCategoriaStart());
    const { data } = yield call(addCategoriaApi, payload);
    yield put(addCategoriaSuccess(data));
    yield put(fetchCategoriasByIdClubAction(payload.club));
  } catch (err) {
    yield put(addCategoriaFail());
  }
}

export function* updateCategoria({ payload }) {
  try {
    yield put(updateCategoriaStart());
    const { data } = yield call(updateCategoriaApi, payload);
    yield put(updateCategoriaSuccess(data));
    yield put(fetchCategoriasByIdClubAction(payload.idClub));
  } catch (err) {
    yield put(updateCategoriaFail());
  }
}

export function* fetchCategoriasByIdClub({ payload }) {
  try {
    yield put(fetchCategoriasByIdClubStart());
    const { data } = yield call(getCategoriasByIdClub, payload);
    yield put(fetchCategoriasByIdClubSuccess(data.categorias));
  } catch (err) {
    yield put(fetchCategoriasByIdClubFail());
  }
}

export default function* categoriasSagas() {
  yield takeLatest(fetchCategoriasAction, fetchCategorias);
  yield takeLatest(addCategoriaAction, addCategoria);
  yield takeLatest(updateCategoriaAction, updateCategoria);
  yield takeLatest(fetchCategoriasByIdClubAction, fetchCategoriasByIdClub);
}
