import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  clubes: {
    isLoading: false,
    error: false,
    data: []
  },
  club: {
    isLoading: false,
    error: false,
    data: {}
  }
};

const sliceName = 'clubeSlice';

const clubSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    fetchClubesStart(state) {
      state.clubes.isLoading = true;
      state.clubes.error = false;
    },
    fetchClubesSuccess(state, action) {
      state.clubes.isLoading = false;
      state.clubes.error = false;
      state.clubes.data = action.payload;
    },
    fetchClubesFail(state) {
      state.clubes.isLoading = false;
      state.clubes.error = true;
    },
    fetchClubByIdStart(state) {
      state.club.isLoading = true;
      state.club.error = false;
    },
    fetchClubByIdSuccess(state, action) {
      state.club.isLoading = false;
      state.club.error = false;
      state.club.data = action.payload;
    },
    fetchClubByIdFail(state) {
      state.club.isLoading = false;
      state.club.error = true;
    },
    addClubStart(state) {
      state.club.isLoading = true;
      state.club.error = false;
    },
    addClubSuccess(state, action) {
      state.club.isLoading = false;
      state.club.error = false;
      state.club.data = action.payload;
    },
    addClubFail(state) {
      state.club.isLoading = false;
      state.club.error = true;
    },
    updateClubStart(state) {
      state.club.isLoading = true;
      state.club.error = false;
    },
    updateClubSuccess(state, action) {
      state.club.isLoading = false;
      state.club.error = false;
      // Update the club in the clubs list
      state.clubes.data = state.clubes.data.map(club =>
        club.id === action.payload.id ? action.payload : club
      );
      state.club.data = action.payload;
    },
    updateClubFail(state) {
      state.club.isLoading = false;
      state.club.error = true;
    },
    setClub(state, action) {
      state.club.data = action.payload;
    }
  }
});

export const fetchClubesAction = createAction(`${sliceName}/fetchClubesAction`);
export const fetchClubByIdAction = createAction(`${sliceName}/fetchClubByIdAction`);
export const addClubAction = createAction(`${sliceName}/addClubAction`);
export const updateClubAction = createAction(`${sliceName}/updateClubAction`);

const { actions, reducer } = clubSlice;

export const {
  fetchClubesStart,
  fetchClubesSuccess,
  fetchClubesFail,
  fetchClubByIdStart,
  fetchClubByIdSuccess,
  fetchClubByIdFail,
  addClubStart,
  addClubSuccess,
  addClubFail,
  updateClubStart,
  updateClubSuccess,
  updateClubFail,
  setClub
} = actions;

export const getClubesDataSelector = state => state.clubes.clubes.data;
export const getClubDataSelector = state => state.clubes.club.data;
export const IsClubesLoading = state => state.clubes.club.isLoading;

export default reducer;
