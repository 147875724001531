import authReducer from './auth';
import clubesReducer from './clubes'
import categoriasReducer from './categorias'
import jugadoresReducer from './jugadores'


export const reducers = {
  auth: authReducer,
  clubes: clubesReducer,
  categorias: categoriasReducer,
  jugadores: jugadoresReducer
};
