import { call, put, takeLatest } from 'redux-saga/effects';
import {
  addClubAction,
  addClubFail,
  addClubStart,
  addClubSuccess,
  fetchClubesAction,
  fetchClubesFail,
  fetchClubesStart,
  fetchClubesSuccess,
  updateClubAction,
  updateClubFail,
  updateClubStart,
  updateClubSuccess,
  fetchClubByIdAction,
  fetchClubByIdStart,
  fetchClubByIdSuccess,
  fetchClubByIdFail
} from '../slice/clubes';
import { addClubApi, getClubes, updateClubApi, getClubByIdApi } from '../api/clubes';

export function* fetchClubes() {
  try {
    yield put(fetchClubesStart());
    const { data } = yield call(getClubes);
    yield put(fetchClubesSuccess(data));
  } catch (err) {
    yield put(fetchClubesFail());
  }
}

export function* fetchClubById({ payload }) {
  try {
    yield put(fetchClubByIdStart());
    const { data } = yield call(getClubByIdApi, payload);
    yield put(fetchClubByIdSuccess(data));
  } catch (err) {
    yield put(fetchClubByIdFail());
  }
}

export function* addClub({ payload }) {
  try {
    yield put(addClubStart());
    const { data } = yield call(addClubApi, payload);
    yield put(addClubSuccess(data));
    yield put(fetchClubesAction()); // Refresca la lista de clubes después de agregar uno nuevo
  } catch (err) {
    yield put(addClubFail());
  }
}

export function* updateClub({ payload }) {
  try {
    yield put(updateClubStart());
    const { data } = yield call(updateClubApi, payload);
    yield put(updateClubSuccess(data));
    yield put(fetchClubesAction()); // Refresca la lista de clubes después de actualizar uno
  } catch (err) {
    yield put(updateClubFail());
  }
}

export default function* clubesSagas() {
  yield takeLatest(fetchClubesAction, fetchClubes);
  yield takeLatest(fetchClubByIdAction, fetchClubById);
  yield takeLatest(addClubAction, addClub);
  yield takeLatest(updateClubAction, updateClub);
}
